import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useCookies } from "react-cookie";
import axios from "axios";

export default function ButtonAppBar() {
  const [cookies, setCookie, removeCookie] = useCookies();

  let Logout = async () => {
    try {
      const logout = await axios
        .post("http://masterapi.yuhmak.com/api/v1/Logout")
        .then((response) => response.data);
      if (logout.status === "ok") {
        removeCookie("Division");
        removeCookie("database");
        removeCookie("Series");
        localStorage.removeItem("userName");
        window.location.assign("/");
      }
    } catch (error) {}
  };

  return (
    <>
      {cookies.Division ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Alta de Articulos
              </Typography>
              <Button color="inherit" onClick={Logout}>
                Cerrar Sesion
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
